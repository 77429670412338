import { createGlobalStyle } from 'styled-components'
import { normalize } from 'polished'

const GlobalStyle = createGlobalStyle`
  ${ normalize() }
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  html,body {
    font-family: ${ props => props.theme.font.base };
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width:100%;
    font-size: 16px;
    background-color: ${ props => props.theme.color.bgPrimary };
  }

  body {
    text-rendering: optimizeLegibility;
  }

  main {
    height: 100%;
    width: 100%;
    z-index: ${ props => props.theme.zIndex.s };
  }

  a {
    color: ${ props => props.theme.color.primary } ;
    text-decoration: none;

    &:hover {
      color: ${ props => props.theme.color.primary } ;
    }
    &:focus,&:active {
      color: ${ props => props.theme.color.base } ;
    }
    &:focus {
      outline: none;
    }
  }
  `

export default GlobalStyle
