import React from 'react'
import { theme } from 'theme'
import { ThemeProvider } from 'styled-components'
import './src/utils/global.css'
import GlobalStyle from 'GlobalStyle'
import languages from './src/data/languages'

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    {element}
  </ThemeProvider>
)

export const onClientEntry = () => {
  const userLang = navigator.language.substr(0, 2)
  const { defaultLangKey } = languages
  const defaultTranslationKey = 'en'

  if (userLang !== defaultLangKey && !window.location.pathname.startsWith(`/${ defaultTranslationKey }`)) {
    window.location.pathname = `/${ defaultTranslationKey }${ window.location.pathname }`
  }
}

let offsetY = 90

const getTargetOffset = hash => {
  const id = window.decodeURI(hash.replace(`#`, ``))
  if (id !== ``) {
    const element = document.querySelector(`[name=${ id }]`)
    if (element) {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      let clientTop =
        document.documentElement.clientTop || document.body.clientTop || 0
      return (
        element.getBoundingClientRect().top + scrollTop - clientTop - offsetY
      )
    }
  }
  return null
}

export const onInitialClientRender = (_, pluginOptions) => {
  if (pluginOptions.offsetY) {
    offsetY = pluginOptions.offsetY
  }

  requestAnimationFrame(() => {
    const offset = getTargetOffset(window.location.hash)
    if (offset !== null) {
      window.scrollTo(0, offset)
    }
  })
}

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  const offset = getTargetOffset(location.hash)
  return offset !== null ? [0, offset] : true
}
