/* eslint-disable max-len */
import { rem } from 'polished'
import { keyframes } from 'styled-components'

const font = {
  base: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
  alternative: `'Merriweather', serif`
}

const color = {
  base: '#363636',
  baseInverse: '#FFFFFF',
  baseLighter: '#757575',
  primary: '#69923F',
  primaryLighter: '#B8E986',
  border: '#E6E6E6',
  borderDarker: '#181C21',
  bg: '#F5F5F5',
  bgLighter: '#FFFFFF',
  bgGrey: '#DFE1E6',
  bgInverse: '#181C21',
  danger: '#FB6D77',
  blue: '#3273DC',
  purple: '#B86BFF',
  warning: '#F5874A',
  success: '#40CB89',
  yellow: '#FFDD57',
  star: '#FFDD57',
  booking: '#013580',
  facebook: '#1977F3'
}

const value = {
  borderRadius: '4px',
  borderRadiusM: '12px',
}

const weight = {
  light: '300',
  base: '400',
  medium: '500',
  bold: '700'
}

const size = {
  base: rem('16px'),
  xxxs: rem('12px'),
  xxs: rem('14px'),
  xs: rem('16px'),
  ls: rem('18px'),
  s: rem('20px'),
  sm: rem('24px'),
  m: rem('32px'),
  ml: rem('42px'),
  l: rem('48px'),
  xl: rem('60px')
}

const spacing = {
  base: rem('16px'),
  xxxs: rem('4px'),
  xxs: rem('8px'),
  xxms: rem('10px'),
  xs: rem('12px'),
  xms: rem('14px'),
  s: rem('20px'),
  sm: rem('22px'),
  ms: rem('24px'),
  m: rem('30px'),
  l: rem('48px'),
  xl: rem('60px'),
  xxl: rem('120px'),
  xxxl: rem('180px'),
}

const zIndex = {
  negative: -10,
  s: 0,
  m: 10,
  l: 20,
  ml: 30,
  xl: 40,
  xxl: 50,
  above: 100
}

const animation = {
  pulse: keyframes`
    0% {transform: scale(0.94);}
    50% {transform: scale(1);}
    100% {transform: scale(0.94);}
  `,
  pop: keyframes`
    0% {
      opacity:0;
      transform: scale(0);
    }
    100% {transform: scale(1);}
  `,
  bounce: keyframes`
    0%, 100% {
      transform: scale(0.0);
    } 50% {
      transform: scale(1.0);
    }
  `
}

const semantic = {
  nav: {
    height: `120px`,
    heightMobile: `80px`,
    heightFix: `224px`,
    heightFixMobile: `184px`
  }
}

export const theme = {
  font,
  weight,
  color,
  spacing,
  size,
  value,
  zIndex,
  animation,
  semantic
}
